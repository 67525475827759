import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-search-input',
  styleUrls: ['./search-input.component.scss'],
  template: `
   
       <a style=" cursor: pointer;"><img src="../../../../assets/images/search (1).png" alt="Search" width="40px;" height="40px"/></a>
    <input placeholder="Type your transaction code here..." 
        
           #input
           (blur)="hideInput()"
           (keyup.enter)="onInput(input.value)">


  `,
})

// <i class="control-icon ion ion-ios-search" 
// (click)="showInput()"></i> (input)="onInput($event)"  [class.hidden]="!isInputShown" //used in input 
// (click)="showInput()"  //used in the image 
// 
export class SearchInputComponent {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  constructor(private route: Router) { }


  roleId
  ngOnInit(): void {

    if (JSON.parse(sessionStorage.getItem('payload')) != null) {
      let myPayload = JSON.parse(sessionStorage.getItem('payload'));
      //console.log(myPayload);


      this.roleId = myPayload[0].roleId;

    }
  }
  isInputShown = false;

  showInput() {
    this.isInputShown = true;
    this.input.nativeElement.focus();
  }

  hideInput() {
    this.isInputShown = false;
  }

  onInput(val: string) {
    this.search.emit(val);

    if (this.roleId === 5002 || this.roleId === 5001) {
      switch (val) {
        case "LT": {
          this.route.navigate(['/pages/task/client-task-manager']);
          break;
        }
        case "AUT": {
          this.route.navigate(['/pages/task/client-task-search']);
          break;

        }
        case "SI-21": {
          this.route.navigate(['/pages/task-invoice/create-sales-invoice']);
          break;
        }
        case "IM": {
          this.route.navigate(['/pages/task-invoice/item-master']);
          break;
        }
        case "SI-23": {
          this.route.navigate(['/pages/task-invoice/search-sales-invoice']);
          break;
        }
        case "SI-24": {
          this.route.navigate(['/pages/task-invoice/cancel-invoice']);
          break;
        }
        case "INVMIS": {
          this.route.navigate(['/pages/reports/invoice-mis']);
          break;
        }
        case "GSTSO": {
          this.route.navigate(['/pages/reports/gst-sales-output']);
          break;
        }
        case "LU": {
          this.route.navigate(['/pages/masters/login-users']);
          break;
        }
        case "CPWD": {
          this.route.navigate(['/pages/masters/change-password']);
          break;
        }
        case "ETM": {
          this.route.navigate(['/pages/masters/emptomgr']);
          break;
        }
        case "ARO": {
          this.route.navigate(['/pages/masters/assign-roles']);
          break;
        }
        case "TGM": {
          this.route.navigate(['/pages/masters/taskgroupmaster']);
          break;
        }
        case "TOSM": {
          this.route.navigate(['/pages/masters/tasksubgroupmaster']);
          break;
        }
        case "CP": {
          this.route.navigate(['/pages/masters/client-profile']);
          break;
        }
        case "CB": {
          this.route.navigate(['/pages/masters/companybranch']);
          break;
        }
        case "DNSC": {
          this.route.navigate(['/pages/masters/document-next-serial-number']);
          break;
        }
        default: {

        }

      }
    }
  }
}
