<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a (click)="navigateHome()" class="logo">CRMS</a>
    <a (click)="goToHome()" style=" cursor: pointer;"><img src="../../../../assets/images/home.png" alt="Home" width="35px;" height="30px"/></a>
    <a (click)="backClicked()" class=" homeClass" ><img src="../../../../assets/images/back-arrow.png" alt="Back" width="35px;" height="30px"/></a>
    <!-- <i class="fa fa-home ; homeClass" aria-hidden="true"  (click)="goToHome()"></i> -->
    <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a (click)="reloadPage()" ><img src="../../../../assets/images/refresh.png" alt="Back" width="30px;" height="25px"/></a> -->
  </div>
  
</div>

<div class="header-container">
    <ngx-search-input></ngx-search-input>
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               name="Developer"
               >
      </nb-user> -->
      <nb-user [nbContextMenu]="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="userName"
          shape="semi-round" 
          >
      </nb-user>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img [src]="img" width="50px" height="50px" *ngIf="img"/>
    </nb-action>
  </nb-actions>
</div>

